.container {
  padding: var(--layout-padding);
  padding-top: 100px;
  padding-bottom: 100px;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}
.video {
  width: 20rem !important;
  height: 15rem !important;
}

@media screen and (max-width: 768px) {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
