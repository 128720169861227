.div header {
  width: 100vw;
  margin: 0 auto;
  font-size: 1.15rem;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
  padding: 0 100px;
  background: #ffffff;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
  transition: background-color 300ms ease-in-out;
  z-index: 999;
}

.div header.scrolled {
  width: 100vw;
  display: flex;
  background-color: #ffffff;
  box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
}

.menu {
  display: flex;
  gap: 2.88rem;
  align-items: center;
  list-style: none;
}
.menu ul {
  margin: 0;
}
.menu li {
  color: #2a2d46;
  text-decoration: none;
  font-weight: 500;
  border-bottom: none;
  transition: color 300ms ease-in-out;
  cursor: pointer;
}

.menu a.active {
  font-weight: bold;
  border-bottom: 2px solid #2a2d46;
}

.menu a:hover {
  color: #064db8;
}

.contact {
  display: flex;
  flex-direction: column;
  gap: 6px;
}
.contact a {
  color: #000000;
  font-weight: 400;
  line-height: 20px;
}
.lastContainer {
  display: flex;
  gap: 30px;
}
.btns {
  display: flex;
  flex-direction: column;
  gap: 7px;
}
.btns button {
  background: none;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
/* .activeLink {
  color: #52dae7 !important;
} */
@media (max-width: 768px) {
  .div header {
    max-width: 768px;
    padding: 0 16px;
    justify-content: space-between;
    transition: height 300ms ease-in-out;
  }
  .contact,
  .btn {
    display: none;
  }
  .burgerBtn {
    display: block;
    cursor: pointer;
    z-index: 1000;
  }
  .menu {
    display: none;
  }
  .active {
    left: 0;
    display: none;
  }

  .burger {
    display: block;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 26px;
    height: 19px;
    margin-top: 31px;
    position: relative;
    z-index: 10000;
  }
  .burger span {
    display: block;
    height: 3px;
    width: 100%;
    margin-top: 5px;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    background-color: #000000;
    border-radius: 3px;
  }
  .burger .shortLine {
    display: block;
    height: 3px;
    width: 21px;
    -webkit-transition: 0.5s all;
    transition: 0.5s all;
    background: #000000;
    border-radius: 3px;
    margin-left: 5px;
  }

  .burger.active span {
    margin-top: -5px;
  }
  .burger.active span:nth-child(1) {
    -webkit-transform: translateY(3px) rotate(-45deg);
    transform: translateY(3px) rotate(-45deg);
    position: relative;
  }
  .burger.active span:nth-child(2) {
    display: none;
  }
  .burger.active span:nth-child(3) {
    -webkit-transform: translateY(5px) rotate(45deg);
    transform: translateY(5px) rotate(45deg);
  }
}
