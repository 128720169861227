.container {
  padding: var(--layout-padding);
  background-image: url("../../assets/images/questions.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
.inner-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  flex-basis: 30%;
}
.accordion {
  flex-basis: 60%;
}
@media screen and (max-width: 768px) {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .inner-wrap {
    flex-direction: column;
  }
  .title {
    flex-basis: 100%;
    margin-bottom: 20px;
  }
  .accordion {
    flex-basis: 100%;
    width: 100%;
  }
}
