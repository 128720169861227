.carousel-image {
  filter: brightness(50%);
}
.text-wrap {
  margin-bottom: 4vh;
}
.title {
  font-size: 5rem;
}
.description {
  font-size: 2rem;
}
@media screen and (max-width: 768px) {
  .carousel-caption {
    display: block !important;
  }
  .text-wrap {
    margin-bottom: 0vh;
  }
  .title {
    font-size: 1.5rem;
  }
  .description {
    font-size: 1.2rem;
  }
}
@media screen and (max-width: 450px) {
  .title {
    font-size: 1.2rem;
  }
  .description {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 375px) {
  .title {
    font-size: 1.2rem;
  }
  .description {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 350x) {
  .title {
    font-size: 1rem;
  }
  .description {
    font-size: 0.6rem;
  }
}
