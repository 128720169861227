.container {
  padding: var(--layout-padding);
  background-image: url("../../assets/images/questions.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  padding-top: 100px;
  padding-bottom: 100px;
}
.title {
  margin-bottom: 20px;
}
@media screen and (max-width: 768px) {
  .container {
    padding-top: 50px;
    padding-bottom: 50px;
  }
}
