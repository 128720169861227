@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600&display=swap");
* {
  box-sizing: border-box;
}
:root {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  --layout-padding: 50px 6%;
}
body {
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f1f7fc;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h2 {
  font-size: 2rem;
  margin: 0;
}
p {
  font-size: 1rem;
  margin: 0;
}
li {
  list-style: none;
}
input,
textarea,
button,
select,
div,
a {
  -webkit-tap-highlight-color: transparent;
}
